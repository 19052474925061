/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/chris-avatar.jpg/" }) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          title
          author
          description
          bio
        }
      }
    }
  `)

  const { title, author, bio } = data.site.siteMetadata

  return (
    <div className="bio">
      <figure className="bio--avatar">
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      </figure>

      <div className="bio--desc">
        <h2 className="bio--title">About {title}</h2>
        <p>
          {bio}
        </p>
      </div>
    </div>
  )
}

export default Bio
