import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import SEO from '../components/seo'
import Section from '../components/section'
import Bio from '../components/bio'
import Layout from '../components/layout'
import Breadcrumbs from '../components/breadcrumbs'

class BlogIndex extends Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages

    const prevPage = (currentPage - 1 === 1
      ? '/blog/'
      : '/blog/page/' + (currentPage - 1).toString() + '/'
    )

    const nextPage = '/blog/page/' + (currentPage + 1).toString() + '/'
    const title = isFirst ? 'Blog' : `Blog | Page ${currentPage}`

    return (
      <Layout currentSection="blog">
        <SEO
          title={title}
          description="Blog posts by Chris Peters about digital strategy, execution, and production."
        />

        <Section>
          <Container>
            <Row className="justify-content-around">
              <Col xs={12} lg={8}>
                <Breadcrumbs>
                  {!isFirst && (
                    <Breadcrumbs.Link to="/blog/">
                      Blog
                    </Breadcrumbs.Link>
                  )}
                </Breadcrumbs>

                <h1 className="mb-5">
                  {isFirst ? 'Blog' : `Page ${currentPage}`}
                </h1>

                {posts.map(({ node }) => {
                  const title = node.frontmatter.title || node.fields.slug
                  const desc = node.frontmatter.description || node.excerpt

                  return (
                    <div key={node.fields.slug} className="mb-5">
                      <h3 className="h4">
                        <Link to={`/blog${node.fields.slug}`}>
                          {title}
                        </Link>
                      </h3>
                      <small>{node.frontmatter.date}</small>
                      <p dangerouslySetInnerHTML={{ __html: desc }} />
                    </div>
                  )
                })}

                <nav
                  className="d-flex mb-5"
                  style={{
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 0,
                  }}
                >
                  {isFirst && <div />}

                  {!isFirst && (
                    <Link to={prevPage} rel="prev">
                      ← Newer Posts
                    </Link>
                  )}

                  {!isLast && (
                    <Link to={nextPage} rel="next">
                      Older Posts →
                    </Link>
                  )}
                </nav>

                <Bio />
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: {fields: {content_type: {eq: "blog"}}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
